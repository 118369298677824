import {
  temporarySceneStorage, USER_PLAN,
} from "../../../src/utils/temporarySceneStorage";
import { ShowNotification } from "../functions/showNotification";
import { CLASSUPER_UPGRADE, SCENE_LIFETIME } from "../constants";
import { t } from "../../../src/i18n";
import {getCookie} from "./getCookie";
import {inIframe} from "../../../src/utils/inIframe";

interface DocumentAccess {
  canOpen: boolean;
  canCollaborate: boolean;
  mustUpdate: boolean;
}

const isDocumentExpired = (
  deletedTimestamp: number | undefined,
  updatedTimestamp: number | undefined,
): boolean => {
  if (deletedTimestamp) {
    return true;
  }

  if (!updatedTimestamp) {
    return false;
  }

  return Date.now() - updatedTimestamp > SCENE_LIFETIME;
};

const showNotification = (
  messageKey: string,
  type: string,
  closable?: boolean,
  linkParams: string[] = [],
) => {
  ShowNotification(
    t(messageKey as keyof typeof t),
    type,
    closable,
    ...linkParams,
  );
};

const showExpiredNotification = (isUserOwner: boolean) => {
  const messageKey = isUserOwner
    ? "errors.sceneStorageTimeOutForOwner"
    : "errors.sceneStorageTimeOutForOther";

  const linkParams: string[] = isUserOwner
    ? [CLASSUPER_UPGRADE, t("errors.tariff")]
    : [];

  showNotification(messageKey, "warning", true, linkParams);
};

export const getDocumentAccess = async (
  storedScene: any,
): Promise<DocumentAccess> => {
  const { uid, updatedTS, deletedTS, plan: storedPlan } = storedScene;

  const isFreePlan = temporarySceneStorage.isFreePlan()
  const isExpired = isDocumentExpired(deletedTS, updatedTS)
  const isOwner = uid === temporarySceneStorage.getUserParamByName("userId")

  const isCurrentUserId = getCookie('user')
  const isMixedOwner = inIframe() ? isOwner : (isCurrentUserId == uid)

  if(!isMixedOwner && isCurrentUserId && storedPlan !== USER_PLAN.free ){
    return {
      canOpen: true,
      canCollaborate: true,
      mustUpdate: false,
    };
  }

  if (isExpired) {
    if (isOwner) {
      if (isFreePlan) {
        showExpiredNotification(true);
      }
      return {
        canOpen: true,
        canCollaborate: !isFreePlan,
        mustUpdate: !isFreePlan,
      };
    }
    // Не владелец не может открыть
    showExpiredNotification(false);
    return { canOpen: false, canCollaborate: false, mustUpdate: false };
  }

  if (!isOwner) {
    return { canOpen: true, canCollaborate: true, mustUpdate: false };
  }

  return { canOpen: true, canCollaborate: true, mustUpdate: true };
};
